<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 language-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          Data region
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper data-region-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            placeholder="Search"
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <section
      class="data-regions"
      v-if="sortedDataRegions && sortedDataRegions.length"
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          v-for="dataRegion in sortedDataRegions"
          :key="dataRegion.id"
        >
          <button
            class="clebex-item-content-wrapper"
            @click="selectDataRegion(dataRegion)"
          >
            <span class="label">{{ dataRegion.name }}</span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </button>
        </li>
      </ul>
    </section>
  </mobile-screen>
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { mapActions, mapState } from "vuex";

export default {
  name: "DataRegionSelection",
  data() {
    return {
      alphabet: [...Array(26)]
        .map((_, y) => String.fromCharCode(y + 65))
        .join(""),
      searchQuery: ""
    };
  },
  mounted() {
    this.setSelectedDataRegion(null);
  },
  computed: {
    ...mapState("dataRegion", ["dataRegions"]),
    sortedDataRegions() {
      if (
        this.dataRegions &&
        this.dataRegions.data &&
        this.dataRegions.data.length
      ) {
        let dataRegions = this.dataRegions.data.slice(0);

        const query = this.searchQuery;

        if (query && query.length > 1) {
          dataRegions = dataRegions.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        }

        return dataRegions.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (b.name < a.name) {
            return 1;
          }
          return 0;
        });
      }
      return null;
    }
  },
  methods: {
    ...mapActions("dataRegion", ["setSelectedDataRegion"]),
    selectDataRegion(dataRegion) {
      this.setSelectedDataRegion(dataRegion);
      this.$router.push({ name: this.backLinkName });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
